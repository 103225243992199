import React, {useEffect, useState} from "react";
import './_contact.scss'
import home1 from '../../assets/images/home1.png'
import home2 from '../../assets/images/home2.png'
import home3 from '../../assets/images/home3.png'
import logoLerouxFilsNoir from "../../assets/images/logoLerouxFilsNoir.png";
import iconLerouxFilsNoir from "../../assets/icons/iconLerouxFilsNoir.svg";
import leftIcon from "../../assets/icons/leftIcon.svg";
import {useHistory} from "react-router-dom";
export default function Contact() {
    const history = useHistory();

    const images = [home1, home2, home3];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);

    const [backgroundColor, setBackgroundColor] = useState('#000')
    const [opacityPage , setOpacityPage] = useState(0);

    useEffect(() => {
        setOpacityPage(1)
        setBackgroundColor('#FFF')
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setOpacity(0); // On commence par réduire l'opacité à 0 pour l'animation
        }, 5000);

        // En utilisant setTimeout, nous attendons la fin de l'animation d'opacité avant de changer d'image.
        const timeout = setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            setTimeout(function() {
                setOpacity(1); // On rétablit l'opacité à 1 après avoir changé d'image
            }, 500);

        }, 5000 + 1000); // on ajoute 500ms pour la durée de l'animation d'opacité

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [currentImageIndex]);

    return(
        <div style={{backgroundColor: backgroundColor, transition: 'background-color ease-in-out 1s'}}>
            <div className={'contact'} style={{opacity: opacityPage}}>
                <div  className={'backToHome'} onClick={() => history.push("/")} style={{zIndex: 20}}>
                    <img src={leftIcon} alt=""/>
                    Retour à l'accueil
                </div>

                <div className={'containerImage'}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'absolute', zIndex: 1, width: 'calc(100vw - 800px)', height: '100vh', minWidth: '50vw'}}>
                        <img src={logoLerouxFilsNoir} alt="" className={'logoLerouxFilsNoir'}/>
                        <img src={iconLerouxFilsNoir} alt="" className={'iconLerouxFilsNoir'}/>

                    </div>

                    <img src={images[currentImageIndex]} alt="" className={'image'} style={{ opacity, transition: 'opacity 1s', zIndex: 10}}/>
                </div>

                <div className={'containerTextForm'}>
                    <p className={'title'}>Contact & Visites</p>
                    <div style={{display: 'flex'}}>
                        <div className={'bar'}/>
                    </div>

                    <div className={'containerInfosContact'}>
                        <div>
                            <p className={'littleTitle'}>Siège social</p>
                            <p className={'corps'}>Chateau Margaux, 33460 Margaux <br/>
                                Tel : +33 (0) 5 57 88 83 83</p>
                        </div>

                        <div>
                            <p className={'littleTitle'}>Siège Administratif</p>
                            <p className={'corps'}>19, avenue Montaigne, 75008 Paris <br/>
                                Tel : +33 (0) 1 44 43 43 20</p>
                        </div>
                    </div>

                    <div className={'containerInfosContact'}>
                        <div>
                            <p className={'littleTitle'}>Ventes</p>
                            <p className={'corps'}>Chateau Margaux, 33460 Margaux <br/>
                                Tel : +33 (0) 5 57 88 83 83</p>
                        </div>

                        <div>
                            <p className={'littleTitle'}>Visites</p>
                            <p className={'corps'}>19, avenue Montaigne, 75008 Paris <br/>
                                Tel : +33 (0) 1 44 43 43 20</p>
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <div className={'bar'} style={{marginBottom: -10, marginTop: 50}}/>
                    </div>

                    <form className={'form'}>
                        <p className={'littleTitle'} style={{marginBottom: 25}}>Une question ?</p>

                        <input type="text" placeholder={'Nom Prénom *'} style={{backgroundColor: backgroundColor, transition: 'background-color ease-in-out 1s'}}/>
                        <input type="text" placeholder={'Adresse Email *'} style={{backgroundColor: backgroundColor, transition: 'background-color ease-in-out 1s'}}/>
                        <textarea name="" id="" cols="30" rows="10" placeholder={'Message'} style={{backgroundColor: backgroundColor, transition: 'background-color ease-in-out 1s'}}></textarea>

                        <div style={{display: 'flex', justifyContent: "flex-end", marginBottom: 30}}>
                            <div className={'button'}>
                                Envoyer
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </div>

    )
}
