import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "./pages/Home Page/HomePage";
import Domaine from "./pages/Domaine/Domaine";
import Contact from "./pages/Contact/Contact";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={HomePage}/>
                <Route path="/le-domaine" exact component={Domaine}/>
                <Route path="/contact" exact component={Contact}/>

                {/* ☝️ TODO #6 Create the pages you need in the project */}

            </Switch>
        </Router>
    );
}

export default App;
