import React, {useEffect, useState} from "react";
import './_largeImage.scss';
import home2 from '../../assets/images/home2.png';
import home1 from "../../assets/images/home1.png";
import home3 from "../../assets/images/home3.png";
import logoLerouwFils from "../../assets/images/logoLerouxFils.png";
import iconLerouxFils from "../../assets/icons/iconLerouxFils.svg";

export default function LargeImage() {

    const images = [home1, home2, home3];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setOpacity(0); // On commence par réduire l'opacité à 0 pour l'animation
        }, 5000);

        // En utilisant setTimeout, nous attendons la fin de l'animation d'opacité avant de changer d'image.
        const timeout = setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            setTimeout(function() {
                setOpacity(1); // On rétablit l'opacité à 1 après avoir changé d'image
            }, 500);

        }, 5000 + 1000); // on ajoute 500ms pour la durée de l'animation d'opacité

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [currentImageIndex]);

    return(
        <div className={'largeImage'}>
            <img src={images[currentImageIndex]} alt="" className={'backgroundImage'} style={{zIndex: 1, opacity: opacity}}/>

            <div className={'containerContent'} style={{zIndex: 3}}>
                <h2>Un domaine unique</h2>
                <p className={'corps'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

                <div className={'containerImageLinks'}>
                    <div className={'image'} onClick={() => setCurrentImageIndex(0)}>
                        <p className={'titleImage'}>Image 1</p>
                        <div className={'backBar'}>
                            <div className={'frontBar'} style={{opacity: currentImageIndex === 0  ? 1 : 0, width: currentImageIndex === 0 ? 0 : 200}}/>
                        </div>
                    </div>

                    <div className={'image'} onClick={() => setCurrentImageIndex(1)}>
                        <p className={'titleImage'}>Image 2</p>
                        <div className={'backBar'}>
                            <div className={'frontBar'} style={{opacity: currentImageIndex === 1  ? 1 : 0, width: currentImageIndex === 1 ? 0 : 200}}/>
                        </div>
                    </div>

                    <div className={'image'} onClick={() => setCurrentImageIndex(2)}>
                        <p className={'titleImage'}>Image 3</p>
                        <div className={'backBar'}>
                            <div className={'frontBar'} style={{opacity: currentImageIndex === 2  ? 1 : 0, width: currentImageIndex === 2 ? 0 : 200}}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'black'}>
                <img src={logoLerouwFils} alt="" className={'logoDomain'}/>
                <img src={iconLerouxFils} alt="" className={'iconDomain'}/>

            </div>
        </div>
    )
}
