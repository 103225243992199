import React, {useEffect, useState} from "react";
import TemplateComponent from "../../components/Template Component/TemplateComponent";
import './_homePage.scss' // 👈 Don't delete
import home1 from '../../assets/images/home1.png'
import home2 from '../../assets/images/home2.png'
import home3 from '../../assets/images/home3.png'
import iconLerouxFils from '../../assets/icons/iconLerouxFils.svg'
import logoLerouwFils from '../../assets/images/logoLerouxFils.png'
import {NavLink} from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function HomePage() {

    const history = useHistory();

    const [width, setWidth] = useState(0);

    window.addEventListener('resize', Update);
    useEffect(() => {
        Update();
    }, []);

    function Update() {
        setWidth(window.innerWidth);
    }

    const [logoOpacity, setLogoOpacity] = useState(0);

    const [generalOpacity1, setGeneralOpacity1] = useState(0);
    const [generalOpacity, setGeneralOpacity] = useState(0);
    const [generalOpacity3, setGeneralOpacity3] = useState(0);

    useEffect(() => {

            setLogoOpacity(true);

            setTimeout(function() {
                setGeneralOpacity1(true);
            }, 1000);

            setTimeout(function() {
                setGeneralOpacity(true);
            }, 1500);

            setTimeout(function() {
                setGeneralOpacity3(true);
            }, 2000);
    }, []);

    function handleClick() {
        setGeneralOpacity1(0);
        setGeneralOpacity(0);
        setGeneralOpacity3(0);
        setLogoOpacity(0);

        setTimeout(function() {
            history.push("/le-domaine"); // Navigate to /le-domaine after the timeout
        }, 1000);
    }

    function handleClickContact() {
        setGeneralOpacity1(0);
        setGeneralOpacity(0);
        setGeneralOpacity3(0);
        setLogoOpacity(0);

        setTimeout(function() {
            history.push("/contact"); // Navigate to /le-domaine after the timeout
        }, 1000);
    }

    return (
        <div className={'homePage'}>

            <div className={'imagesContainer'}>

                <div className={'image-container'} style={{opacity: generalOpacity1}}>
                    <img src={home1} alt="" className={'coverImage'}/>

                    <div className={'part'} style={{zIndex: 10}}>

                        <div className={'content'}>
                            <img src={logoLerouwFils} alt="" className={'logoDomain'} style={{opacity: width < 1101 ? logoOpacity: 0}}/>
                            <img src={iconLerouxFils} alt="" className={'iconDomain'}  style={{opacity: width < 1101 ? logoOpacity: 0}}/>

                            <div className={'menu'} onClick={handleClick}>
                                <p className={'title'}>Le Domaine</p>

                                <div className={'links'}>
                                    <p className={'link'}>Le Terroir</p>
                                    <p className={'link'}>L'histoire</p>
                                    <p className={'link'}>Les Équipes</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={'image-container'}>
                    <img src={home2} alt=""  className={'coverImage'}  style={{opacity: generalOpacity, zIndex: 1}}/>

                    <div className={'part'} style={{zIndex: 10}}>

                        <div className={'content'}>
                            {width > 1100 &&
                                <>
                                    <img src={logoLerouwFils} alt="" className={'logoDomain'} style={{opacity: width > 1100 ? logoOpacity : 0}}/>
                                    <img src={iconLerouxFils} alt="" className={'iconDomain'} style={{opacity: width > 1100 ? logoOpacity : 0}}/>

                                </>
                            }


                            <div className={'menu'}  style={{opacity: generalOpacity}}>
                                <p className={'title'}>Le Savoir-Faire</p>

                                <div className={'links'}>
                                    <p className={'link'}>Le Travail de la vinge</p>
                                    <p className={'link'}>Le Travail des chais</p>
                                    <p className={'link'}>La conservation de nos vins</p>
                                    <p className={'link'}>Le service & la dégustation</p>
                                </div>

                            </div>

                            {width > 1100 &&
                                <div onClick={handleClickContact} className={'contactVisites'}>
                                    <p style={{opacity: generalOpacity}}>Contact & Visites</p>
                                </div>
                            }


                        </div>

                    </div>
                </div>

                <div className={'image-container'}  style={{opacity: generalOpacity3}}>
                    <img src={home3} alt="" className={'coverImage'}/>


                    <div className={'part'} style={{zIndex: 10}}>

                        <div className={'content'}>

                            {width > 1100 &&
                                <>
                                    <img src={logoLerouwFils} alt="" className={'logoDomain'} style={{opacity: 0}}/>
                                    <img src={iconLerouxFils} alt="" className={'iconDomain'} style={{opacity: 0}}/>

                                </>
                            }


                            <div className={'menu'}>
                                <p className={'title'}>Les Vins</p>

                                <div className={'links'}>
                                    <p className={'link'}>Grand vin</p>
                                    <p className={'link'}>Pavillon Rouge</p>
                                    <p className={'link'}>Pavillon blanc</p>
                                    <p className={'link'}>Margaux</p>
                                </div>
                            </div>

                            {width < 1101 &&
                                <div onClick={handleClickContact} className={'contactVisites'}>
                                    <p style={{opacity: generalOpacity}}>Contact & Visites</p>
                                </div>
                            }
                        </div>

                    </div>
                </div>

            </div>







        </div>
    );
}
