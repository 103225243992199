import React from "react";
import './_footer.scss';
import logoLerouxFilsNoir from '../../assets/images/logoLerouxFilsNoir.png'
import iconLerouxFilsNoir from '../../assets/icons/iconLerouxFilsNoir.svg'
export default function Footer({width}) {
    return(
        <div className={'footer'}>
            <div className={'containerFooter'}>
                <div style={{marginLeft: 30}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: -90}}>
                        <img src={logoLerouxFilsNoir} alt="" className={'logoLerouxFilsNoir'}/>
                        <img src={iconLerouxFilsNoir} alt="" className={'iconLerouxFilsNoir'}/>

                    </div>
                     <p className={'littleTitleGrey'} style={{marginBottom: 5}}>Suivez-nous</p>
                    <p className={'LinkFooter'}>Instagram</p>
                    <p className={'littleTitleGrey'} style={{marginTop: 30, fontSize: 12 }}>@2023 Château Leroux & Fils</p>
                    <p className={'littleTitleGrey'} style={{fontSize: 12, opacity: 0}}>.</p>
                </div>

                <div style={{display: 'flex', marginRight: 30, flexDirection: width < 600 ? 'column' : 'row'}}>

                    {width > 700 &&
                        <div>
                            <p className={'littleTitleGrey'}>Get started</p>
                            <p className={'LinkFooter'}>Pricing</p>
                            <p className={'LinkFooter'}>Account</p>
                        </div>
                    }


                    <div style={{marginLeft: width < 600 ? 0 : 70}}>
                        <p className={'littleTitleGrey'}>Legal</p>
                        <a href={'https://opusoft.ai/terms'} target="_blank">
                            <p className={'LinkFooter'}>Terms & Conditions</p>
                        </a>
                        <a href={'https://opusoft.ai/privacy-policy'} target="_blank">
                            <p className={'LinkFooter'}>Privacy policy</p>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    )
}
