import React from "react";
import './_newsletter.scss';
import newsletter from '../../assets/images/newsletter.png'

export default function Newsletter() {
    return(
        <div className={'newsletter'} style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${newsletter})`}}>
            <div className={'leftPart'}>
                <p className={'title'}>Demandez plus <br/> d'informations</p>
                <p className={'subtitle'}>Découvrez toutes nos actualités</p>
            </div>

            <div className={'rightPart'}>

                <div className={'containerInput'}>
                    <input type="text" placeholder={'Votre email'}/>
                    <div className={'button'}>
                        S'inscrire
                    </div>
                </div>
                <p className={'warning'}>En cliquant sur S'inscrire, vous acceptez de recevoir nos histoires et vous acceptez
                    nos conditions et notre politique de confidentialité.</p>
            </div>

        </div>
    )
}
