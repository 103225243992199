import React, {useState, useRef, useEffect} from "react";
import iconLerouxFilsNoir from '../../assets/icons/iconLerouxFilsNoir.svg';
import home1 from '../../assets/images/home1.png';
import home2 from '../../assets/images/home2.png';
import home3 from '../../assets/images/home3.png';
import newsletter from '../../assets/images/newsletter.png';
import leftIcon from '../../assets/icons/leftIcon.svg'
import './_domaine.scss'
import Newsletter from "../../components/Newsletter/Newsletter";
import Footer from "../../components/Footer/Footer";
import ThreeLinks from "../../components/ThreeLinks/ThreeLinks";
import LargeImage from "../../components/LargeImage/LargeImage";
import {useHistory} from "react-router-dom";
export default function Domaine() {
    const history = useHistory();

    const [width, setWidth] = useState(800);

    useEffect(() => {
        window.addEventListener("resize", Update);
        Update();
        return () => window.removeEventListener("resize", Update);
    }, []);

    function Update() {
        setWidth(window.innerWidth);
    }

    const [page, setPage] = useState('terroir');

    const [backgroundColor, setBackgroundColor] = useState('#000');

    useEffect(() => {
        setBackgroundColor('#FFF');
    }, []);

    return(
        <div className={'domainePage'} style={{backgroundColor: backgroundColor}}>
            <div  className={'backToHome'} style={{zIndex: 20}} onClick={() => history.push("/")}>
                <img src={leftIcon} alt=""/>
                Retour à l'accueil
            </div>

            <div className={'stickContainer'}>

                {width > 1200 ?
                    <div className={'imagesContainer'}>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: width < 1200 ? 'flex-start' : 'flex-end'}}>
                            <img src={newsletter} alt="" style={{width: 150, height: 150, marginRight: 5}}/>
                            <img src={home2} alt="" style={{width: 300, height: 300}}/>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginTop: 5}}>
                            <img src={home3} alt="" style={{width: 200, height: 320, marginRight: 5}}/>
                            <img src={home1} alt="" style={{width: 200, height: 320}}/>
                        </div>
                        <img src={newsletter} alt="" style={{width: 500, height: 500, marginTop: 5}}/>
                        <img src={home3} alt="" style={{marginTop: 5, width: 300, height: 300}}/>

                    </div>
                    :
                    <div className={'imagesContainerMobile'}>
                            <img src={newsletter} alt=""/>
                            <img src={home2} alt=""/>
                            <img src={home3} alt=""/>
                            <img src={home1} alt=""/>
                            <img src={newsletter} alt=""/>
                            <img src={home3} alt=""/>
                    </div>
                }



                <StickComponent page={page} setPage={setPage} width={width}/>

            </div>

            <LargeImage/>

            <ThreeLinks/>

            <Newsletter/>

            <Footer width={width}/>

        </div>
    )
}


function StickComponent({light, page, setPage, width}) {
    const [isSticky, setIsSticky] = useState(true);
    const rightRef = useRef(null);

    const handleScroll = () => {
        if (rightRef.current) {
            const { top } = rightRef.current.getBoundingClientRect();
            if (top <= 0) {
                setIsSticky(false);
            } else {
                setIsSticky(true);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="containerStick">

            <div className={`left ${isSticky ? 'sticky' : ''}`}>

                {!light &&
                    <div className={'menu'}>
                        <div className={'links'}>
                            <div className={page === 'terroir' ? 'actualContainerLink' : 'containerLink'} onClick={() => setPage('terroir')}>
                                <img src={iconLerouxFilsNoir} alt="" className={'iconDomain'}/>
                                <p className={'link'}>Le Terroir</p>
                            </div>
                            <div className={page === 'histoire' ? 'actualContainerLink' : 'containerLink'} onClick={() => setPage('histoire')}>
                                <img src={iconLerouxFilsNoir} alt="" className={'iconDomain'}/>
                                <p className={'link'}>L'Histoire</p>
                            </div>
                            <div className={page === 'famille' ? 'actualContainerLink' : 'containerLink'} onClick={() => setPage('famille')}>
                                <img src={iconLerouxFilsNoir} alt="" className={'iconDomain'}/>
                                <p className={'link'}>La famille</p>
                            </div>
                        </div>
                    </div>
                }

                {page === 'terroir' ?
                    <p className={'title'}>Un terroir unique</p>
                    :
                    <p className={'title'}>L'Histoire Leroux & Fils</p>
                }

                {page === 'terroir' ?
                    <p className={'subtitle'}>Un joyau viticole au cœur de la Bourgogne.</p>
                    :
                    <p className={'subtitle'}>Des racines profondes dans la tradition viticole de la Bourgogne.</p>
                }

                {page === 'terroir' ?
                    <p className={'corps'}>

                        Situé dans les vallées pittoresques de la Bourgogne, le domaine Leroux & Fils bénéficie d'un terroir d'exception, riche en minéraux et baigné par un climat idéal. Chaque parcelle de nos vignes, nourrie par cette terre bourguignonne unique, produit des raisins d'une qualité inégalée, qui se transforment en vins d'une profondeur et d'une complexité remarquables.
                        <br/><br/>
                        Nous valorisons la symbiose entre la vigne et la terre, adoptant des méthodes durables pour préserver la pureté de notre héritage. Invitant tous à savourer l'essence de la Bourgogne à travers chaque goutte, Leroux & Fils est le reflet d'une passion intemporelle pour la viticulture.
                    </p>
                    :
                    <p className={'corps'}>
                        Fondé en 1845 par Gustave Leroux, ce domaine bourguignon a rapidement établi sa marque grâce à un dévouement inébranlable à l'excellence. Chaque génération, depuis Gustave, a enrichi notre savoir-faire, mêlant tradition et innovation. Les défis, qu'ils soient climatiques ou historiques, n'ont fait que renforcer notre passion pour la vigne et le vin. Aujourd'hui, honorant à la fois notre héritage et l'avenir, Leroux & Fils se distingue par des vins d'une authenticité et d'une finesse exemplaires, reflétant le meilleur de la Bourgogne.
                    </p>
                }


            </div>
        </div>
    );
}
