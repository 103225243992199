import React from "react";
import './_threeLinks.scss'
import home1 from '../../assets/images/home1.png'
import home2 from '../../assets/images/home2.png'
import home3 from '../../assets/images/home3.png'
export default function ThreeLinks({width}) {

    return(
        <div className={'threeLinks'}>

            <p className={'title'}>Lorem ipsum dolor sit amet</p>
            <p className={'corps'}>Joseph was 22 years old when he took over a long-
                established business in the historic heart of Beaune.
                Starting in 1918, after Joseph the founder, Maurice the visionary aimed to expand the family companyny</p>
            <p className={'defiler'}>{'< Défiler >'}</p>
            <div className={'containerLinks'}>
                <div className={'comble'}/>
                <div className={'link'}>
                    <img src={home1} alt=""/>
                    <p>LEROUX & FILS - LE DOMAINE</p>
                </div>


                <div className={'link'}>
                    <img src={home2} alt=""/>
                    <p>LEROUX & FILS - LE SAVOIR FAIRE</p>

                </div>


                <div className={'link'}>
                    <img src={home3} alt=""/>
                    <p>LEROUX & FILS - NOS VINS</p>

                </div>
                <div style={{opacity: 0}}>
                    .
                </div>

            </div>

        </div>
    )
}
